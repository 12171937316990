<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="filters-row df">
          <h2>{{ $t('settings') }}</h2>
        </div>
        <div class="row justify-content-md-center">
          <div
            class="col-lg-12 col-sm-12"
            :on-enter-key-press="saveChanges"
          >
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="countDownChanged"
            >
              {{ alertText }}
            </b-alert>
            <b-card no-body class="mb-2">
              <b-card-header v-b-toggle.accordion-display class="p-3">
                {{ $t('buildingDisplay') }}
              </b-card-header>
              <b-collapse id="accordion-display" visible>
                <b-card-body>
                  <b-row>
                    <div class="form-group col-4">
                      <label>{{ $t('displayName') }}</label>
                      <b-form-input
                        v-model="settings.displayName"
                      ></b-form-input>
                      <label class="mt-3">{{
                        $t('buildingTimezone')
                      }}</label>
                      <b-form-input
                        v-model="
                          settings.reservationSettings.timezone
                        "
                        disabled
                      ></b-form-input>
                      <label class="mt-3">{{
                        $t('buildingAddress')
                      }}</label>
                      &nbsp;
                      <span
                        v-b-tooltip.hover
                        class="tip-icon"
                        :title="$t('buildingAddressHint')"
                      >
                        <font-awesome-icon icon="question-circle" />
                      </span>
                      <b-form-input
                        v-model="settings.buildingAddress"
                      ></b-form-input>
                    </div>
                    <div class="form-group col-8">
                      <label>{{ $t('popupText') }}</label>
                      <editor
                        v-model="settings.dashboardSettings.popupText"
                        :init="{
                          height: 300,
                          menubar: false,
                          branding: false,
                          content_css: false,
                          skin: false,
                          plugins: ['lists image media table'],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                          invalid_elements: 'script'
                        }"
                      />
                    </div>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
              <b-card-header
                v-b-toggle.accordion-policies
                class="p-3"
              >
                {{ $t('reservationPolicies') }}
              </b-card-header>
              <b-collapse id="accordion-policies" visible>
                <b-card-body>
                  <b-row
                    v-if="
                      settings.reservationSettings.timeslot ===
                        false ||
                        settings.reservationSettings.timeslot ===
                          undefined
                    "
                  >
                    <div class="col-6">
                      <label>{{
                        $t('defaultReservationPeriod')
                      }}</label>
                      &nbsp;
                      <span
                        v-b-tooltip.hover
                        class="tip-icon"
                        :title="$t('defaultReservationPeriodHint')"
                      >
                        <font-awesome-icon icon="question-circle" />
                      </span>
                      <b-form-input
                        v-model.number="
                          settings.reservationSettings.defaultPeriod
                        "
                        type="number"
                        min="15"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                        default="60"
                      >
                      </b-form-input>
                    </div>
                    <div class="col-6">
                      <label>{{ $t('reservationTimeLimit') }}</label>
                      &nbsp;
                      <span
                        v-b-tooltip.hover
                        class="tip-icon"
                        :title="$t('reservationTimeLimitHint')"
                      >
                        <font-awesome-icon icon="question-circle" />
                      </span>
                      <b-form-input
                        v-model.number="
                          settings.reservationSettings
                            .reservationTimeLimit
                        "
                        type="number"
                        min="15"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                        default="180"
                      >
                      </b-form-input>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <div class="form-group col-6">
                      <b-form-checkbox
                        v-model="
                          settings.reservationSettings.timeslot
                        "
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('enforceTimeslots') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('enforceTimeslotsHint')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                  </b-row>
                  <hr />
                  <b-row class="mt-3">
                    <div class="form-group col-9">
                      <b-form-checkbox
                        v-model="buildingMaximum"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('buildingMaximum') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('buildingMaximumHint')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div
                      v-if="buildingMaximum"
                      class="form-group col-6"
                    >
                      <label>{{ $t('buildingMaximumValue') }}</label>
                      <b-form-input
                        v-model.number="
                          settings.reservationSettings.buildingMaximum
                        "
                        type="number"
                        min="1"
                      ></b-form-input>
                    </div>
                  </b-row>
                  <hr v-if="buildingMaximum" />
                  <b-row class="mt-3">
                    <div class="form-group col-9">
                      <b-form-checkbox
                        v-model="totalReservationsLimit"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('totalReservationsLimit') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('totalReservationsLimitHint')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div
                      v-if="totalReservationsLimit"
                      class="form-group col-6"
                    >
                      <label>{{
                        $t('totalReservationsLimitValue')
                      }}</label>
                      <b-form-input
                        v-model.number="
                          settings.reservationSettings
                            .totalReservationsLimit
                        "
                        type="number"
                        min="1"
                      ></b-form-input>
                    </div>
                  </b-row>
                  <hr v-if="totalReservationsLimit" />

                  <b-row class="mt-3">
                    <div class="form-group col-9">
                      <b-form-checkbox
                        v-model="futureDayMax"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('futureDayMax') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('futureDayMaxHint')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div
                      v-if="futureDayMax"
                      class="form-group  col-6"
                    >
                      <label>{{ $t('futureDayMaxValue') }}</label>
                      <b-form-input
                        v-model.number="
                          settings.reservationSettings
                            .futureDayMaximum
                        "
                        type="number"
                        min="1"
                      ></b-form-input>
                    </div>
                  </b-row>
                  <hr v-if="futureDayMax" />
                  <b-row class="mt-3">
                    <div class="form-group col-9">
                      <b-form-checkbox
                        v-model="dailyMaximum"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('dailyMaximum') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('dailyMaximumHint')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div
                      v-if="dailyMaximum"
                      class="form-group  col-6"
                    >
                      <label>{{ $t('dailyMaximumValue') }}</label>
                      <b-form-input
                        v-model.number="
                          settings.reservationSettings.dailyMaximum
                        "
                        type="number"
                        min="1"
                      ></b-form-input>
                    </div>
                  </b-row>
                  <hr v-if="dailyMaximum" />

                  <b-row class="mt-3">
                    <div class="form-group col-6">
                      <b-form-checkbox
                        v-model="weeklyLimit"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('weeklyLimit') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('weeklyLimitHint')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div class="form-group col-6">
                      <b-form-checkbox
                        v-model="userpoolDailyBudget"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('userpoolDailyBudget') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('userpoolDailyBudgetHint')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                  </b-row>
                  <b-row class="mt-3">
                    <div class="col-6">
                      <b-form-checkbox
                        v-model="enableOverlappingReservation"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('enableOverlappingReservation') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="
                            $t('enableOverlappingReservationHint')
                          "
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div class="col-6">
                      <b-form-checkbox
                        v-model="ongoingReservationCancel"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('ongoingReservationCancel') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('ongoingReservationCancelHint')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
              <b-card-header v-b-toggle.accordion-checkin class="p-3">
                {{ $t('checkinSettings') }}
              </b-card-header>
              <b-collapse id="accordion-checkin" visible>
                <b-card-body>
                  <b-row>
                    <div class="form-group col-6">
                      <b-form-checkbox
                        v-model="
                          settings.reservationSettings.staticQrCode
                        "
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('staticQrCode') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('staticQrCodeHint')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="
                          enablePhysicalDistancingCheckinScreen
                        "
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{
                          $t('enablePhysicalDistancingCheckinScreen')
                        }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="
                            $t(
                              'enablePhysicalDistancingCheckinScreenHint'
                            )
                          "
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div class="form-group col-6">
                      <b-form-checkbox
                        v-model="
                          settings.reservationSettings
                            .allowCheckInsWithoutReservation
                        "
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('allowCheckInsWithoutReservation') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="
                            $t('allowCheckInsWithoutReservationHint')
                          "
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-6">
                      <label>{{ $t('earlyEntryMinutes') }}</label>
                      &nbsp;
                      <span
                        v-b-tooltip.hover
                        class="tip-icon"
                        :title="$t('earlyEntryMinutesHint')"
                      >
                        <font-awesome-icon icon="question-circle" />
                      </span>
                      <b-form-input
                        v-model.number="
                          settings.reservationSettings
                            .earlyEntryMinutes
                        "
                        type="number"
                        min="0"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                      </b-form-input>
                    </div>
                    <div
                      v-if="settings.modules.sensor"
                      class="form-group col-6"
                    >
                      <label>{{
                        $t('deskReservationTimeout')
                      }}</label>
                      &nbsp;
                      <span
                        v-b-tooltip.hover
                        class="tip-icon"
                        :title="$t('deskReservationTimeoutHint')"
                      >
                        <font-awesome-icon icon="question-circle" />
                      </span>
                      <b-form-input
                        v-model.number="
                          settings.reservationSettings
                            .deskReservationTimeout
                        "
                        type="number"
                        min="0"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                        placeholder="10"
                      >
                      </b-form-input>
                    </div>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
              <b-card-header v-b-toggle.accordion-1 class="p-3">
                {{ $t('allowFindingColleagues') }}
              </b-card-header>
              <b-collapse id="accordion-1" visible>
                <b-card-body>
                  <b-row>
                    <div class="form-group col-6">
                      <b-form-checkbox
                        v-model="findMyColleagues"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('allowFindingColleagues') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('findColleaguesWarning')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <div
                      v-if="settings.findMyColleagues"
                      class="form-group col-6"
                    >
                      <b-form-checkbox
                        v-model="settings.allowPrivateReservations"
                        :value="true"
                        :unchecked-value="false"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('allowPrivateReservation') }}
                        <span
                          v-b-tooltip.hover
                          class="tip-icon"
                          :title="$t('privateReservationWarning')"
                        >
                          <font-awesome-icon icon="question-circle" />
                        </span>
                      </b-form-checkbox>
                    </div>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
              <b-card-header v-b-toggle.accordion-guests class="p-3">
                {{ $t('guests') }}
              </b-card-header>
              <b-collapse id="accordion-guests" visible>
                <b-card-body>
                  <b-row>
                    <div class="form-group col-6">
                      <b-form-checkbox
                        v-model="
                          settings.enableAllUsersToInviteGuests
                        "
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('enableUsersToInviteGuestsMessage') }}
                      </b-form-checkbox>
                    </div>
                  </b-row></b-card-body
                ></b-collapse
              >
            </b-card>
            <b-card no-body class="mb-2">
              <b-card-header
                v-b-toggle.accordion-subjects
                class="p-3"
              >
                {{ $t('allowReservationSubjects') }}
              </b-card-header>
              <b-collapse id="accordion-subjects" visible>
                <b-card-body>
                  <b-row>
                    <div class="form-group col-6">
                      <b-form-checkbox
                        v-model="settings.allowRoomReservationSubject"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('allowRoomReservationSubjects') }}
                      </b-form-checkbox>
                    </div>
                    <div class="form-group col-6">
                      <b-form-checkbox
                        v-model="settings.allowDeskReservationSubject"
                        :disabled="
                          loading ||
                            !(isCustomerAdmin || isDashboardAdmin)
                        "
                      >
                        {{ $t('allowDeskReservationSubjects') }}
                      </b-form-checkbox>
                    </div>
                  </b-row></b-card-body
                ></b-collapse
              >
            </b-card>
            <b-card no-body class="mb-2">
              <b-card-header v-b-toggle.accordion-links class="p-3">
                {{ $t('usefulLinks') }}
              </b-card-header>
              <b-collapse id="accordion-links" visible>
                <b-card-body>
                  <div
                    v-if="settings.groupAccessCode"
                    class="form-group"
                  >
                    <label>{{ $t('visitorAccessLink') }}:</label>
                    <b-form-input
                      :value="getVisitorAccessLink()"
                      readonly
                    />
                  </div>
                  <div class="form-group">
                    <label>{{ $t('checkInLink') }}:</label>
                    <b-form-input
                      :value="getCheckInLink()"
                      readonly
                    />
                  </div>
                  <div class="form-group">
                    <label>{{ $t('myReservationsLink') }}:</label>
                    <b-form-input
                      :value="getMyReservationsLink()"
                      readonly
                    />
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-2">
              <b-card-header
                v-b-toggle.accordion-user-settings
                class="p-3"
              >
                {{ $t('removeUsersSettings') }}
              </b-card-header>
              <b-collapse id="accordion-user-settings" visible>
                <b-card-body>
                  <div class="form-group">
                    <label>{{ $t('removeUsersAfterDays') }}:</label>
                    <b-form-input
                      v-model="settings.removeUsersAfterDays"
                      type="number"
                    />
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
            <div class="mt-4 col-12" align="end">
              <b-button
                right
                size="lg"
                variant="primary"
                @click="saveChanges"
                >{{ $t('save') }}</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import OkkuApi from '@/services/OkkuApi'
import { UPDATE_RESERVATION_SUBJECT_VISIBILITY } from '@/store/modules/common/mutationTypes'

import tinymce from 'tinymce/tinymce'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/code'
import 'tinymce/themes/silver'
import 'tinymce/skins/ui/oxide/skin.css'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/icons/default/icons.min'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'BuildingSettingsPage',
  components: { editor: Editor },
  data() {
    return {
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: '',
      loading: false,
      enableOverlappingReservation: false,
      dailyMaximum: false,
      futureDayMax: false,
      buildingMaximum: false,
      weeklyLimit: false,
      userpoolDailyBudget: false,
      ongoingReservationCancel: false,
      totalReservationsLimit: false,
      enablePhysicalDistancingCheckinScreen: true,
      htmlToolbarOptions: {
        modules: {
          toolbar: {
            container: [
              [{ font: [] }],
              [{ header: [false, 1, 2, 3, 4, 5, 6] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ align: [] }],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { list: 'check' }
              ],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ color: [] }, { background: [] }],
              ['link', 'image'],
              ['clean']
            ]
          }
        }
      },
      removeUsersAfterDays: 0,
      oldPopUpText: '',
      buildingAddress: ''
    }
  },
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      userInfo: '$userInfo',
      isDashboardAdmin: '$isDashboardAdmin',
      isCustomerAdmin: '$isCustomerAdmin',
      isCustomerCoordinator: '$isCustomerCoordinator',
      settings: '$settings'
    })
  },
  watch: {
    totalReservationsLimit(newValue) {
      if (
        newValue &&
        this.settings.reservationSettings.totalReservationsLimit === 0
      )
        this.settings.reservationSettings.totalReservationsLimit = 1
    },
    buildingMaximum(newValue) {
      if (
        newValue &&
        !this.settings.reservationSettings.buildingMaximum
      )
        this.settings.reservationSettings.buildingMaximum = 1
    },
    dailyMaximum(newValue) {
      if (newValue && !this.settings.reservationSettings.dailyMaximum)
        this.settings.reservationSettings.dailyMaximum = 1
    },
    enablePhysicalDistancingCheckinScreen(newValue) {
      this.settings.dashboardSettings.enablePhysicalDistancingCheckinScreen = newValue
    }
  },
  async mounted() {
    this.findMyColleagues = this.settings.findMyColleagues
    if (!this.settings.reservationSettings) {
      await this.loadSettings(localStorage.BUILDING)

      tinymce.init({})
    }
    // Save old text for later comparison
    this.oldPopUpText = this.settings.dashboardSettings.popupText
    if (
      this.settings.reservationSettings.policies.includes(
        'disable-overlapping-reservation'
      )
    ) {
      this.enableOverlappingReservation = false
    } else {
      this.enableOverlappingReservation = true
    }
    if (
      this.settings.reservationSettings.policies.includes(
        'daily-maximum'
      )
    ) {
      this.dailyMaximum = true
    }
    if (
      this.settings.reservationSettings.policies.includes(
        'future-day-max'
      )
    ) {
      this.futureDayMax = true
    }
    if (
      this.settings.reservationSettings.policies.includes(
        'ongoing-reservation-cancel'
      )
    ) {
      this.ongoingReservationCancel = true
    }
    if (this.settings.reservationSettings.totalReservationsLimit) {
      this.totalReservationsLimit = true
    }
    if (
      this.settings.reservationSettings.policies.includes(
        'building-maximum'
      )
    ) {
      this.buildingMaximum = true
    }
    if (
      this.settings.reservationSettings.policies.includes(
        'weekly-limit'
      )
    ) {
      this.weeklyLimit = true
    }
    if (
      this.settings.reservationSettings.policies.includes(
        'userpool-daily-budget'
      )
    ) {
      this.userpoolDailyBudget = true
    }
    if (
      this.settings.dashboardSettings
        .enablePhysicalDistancingCheckinScreen === false
    ) {
      this.enablePhysicalDistancingCheckinScreen = false
    } else {
      this.enablePhysicalDistancingCheckinScreen = true
    }
  },

  methods: {
    ...mapActions('common', ['loadSettings']),

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },

    async saveChanges() {
      const {
        defaultPeriod,
        reservationTimeLimit
      } = this.settings.reservationSettings

      if (defaultPeriod % 15) {
        this.updateAlert({
          variant: 'danger',
          text:
            'Default length of a reservation must be divisible by 15'
        })
        return
      }
      if (reservationTimeLimit % 15) {
        this.updateAlert({
          variant: 'danger',
          text:
            'Maximum duration of a reservation must be divisible by 15'
        })
        return
      }

      if (
        reservationTimeLimit &&
        defaultPeriod > reservationTimeLimit
      ) {
        this.updateAlert({
          variant: 'danger',
          text:
            'Default length cannot be greater than Maximum duration of a reservation'
        })
        return
      }

      if (this.loading) return
      this.loading = true
      try {
        // construct policies array
        const policies = []
        if (!this.enableOverlappingReservation === true) {
          policies.push('disable-overlapping-reservation')
        }
        if (this.dailyMaximum) {
          policies.push('daily-maximum')
        }
        if (this.futureDayMax) {
          policies.push('future-day-max')
        }
        if (this.ongoingReservationCancel) {
          policies.push('ongoing-reservation-cancel')
        }
        if (this.buildingMaximum) {
          policies.push('building-maximum')
        }
        if (this.weeklyLimit) {
          policies.push('weekly-limit')
        }
        if (this.userpoolDailyBudget) {
          policies.push('userpool-daily-budget')
        }

        // Disable policies if check-boxes are unchecked
        if (!this.totalReservationsLimit) {
          this.settings.reservationSettings.totalReservationsLimit = 0
        }
        if (!this.dailyMaximum) {
          this.settings.reservationSettings.dailyMaximum = 0
        }
        if (!this.buildingMaximum) {
          this.settings.reservationSettings.buildingMaximum = 0
        }
        if (!this.futureDayMax) {
          this.settings.reservationSettings.futureDayMaximum = 0
        }

        this.settings.reservationSettings.policies = policies

        // Check if pop up text is changed?
        if (
          JSON.stringify(this.oldPopUpText) ===
          JSON.stringify(this.settings.dashboardSettings.popupText)
        ) {
          this.settings.reservationSettings.isPopUpTextChanged = false
        } else {
          // Update store
          const orgs = { ...this.userInfo.groups }

          const path = this.organisation.split('/')[2]
          Object.entries(orgs).forEach(org => {
            if (path === org[0].split('/')[2]) {
              org[1].popupText = this.settings.dashboardSettings.popupText
              org[1].showPopUpText = true
            }
          })

          this.$store.commit(
            `common/${'UPDATE_POP_UP_TEXT_STATUS'}`,
            orgs
          )
          this.settings.reservationSettings.isPopUpTextChanged = true
        }
        const params = {
          findMyColleagues: this.findMyColleagues,
          allowPrivateReservations: this.settings.findMyColleagues
            ? this.settings.allowPrivateReservations
            : false,
          enableAllUsersToInviteGuests: this.settings
            .enableAllUsersToInviteGuests,
          allowDeskReservationSubject: this.settings
            .allowDeskReservationSubject,
          allowRoomReservationSubject: this.settings
            .allowRoomReservationSubject,
          reservationSettings: this.settings.reservationSettings,
          dashboardSettings: this.settings.dashboardSettings,
          displayName: this.settings.displayName,
          removeUsersAfterDays: this.settings.removeUsersAfterDays
            ? this.settings.removeUsersAfterDays
            : null,
          buildingAddress: this.settings.buildingAddress
        }
        const response = await OkkuApi.updateBuildingSetting(params)
        this.$store.commit(
          `common/${UPDATE_RESERVATION_SUBJECT_VISIBILITY}`,
          {
            deskReservationSubject: this.settings
              .allowDeskReservationSubject,
            roomReservationSubject: this.settings
              .allowRoomReservationSubject
          }
        )
        if (response) {
          this.updateAlert({
            variant: `${response.status}`,
            text: response.message
          })
          this.settings.findMyColleagues = this.findMyColleagues
          await this.loadSettings(localStorage.BUILDING)
        }
      } catch (error) {
        console.error(error)
        if (error.data) {
          this.updateAlert({
            variant: error.data.status,
            text: error.data.message
          })
        } else {
          this.updateAlert({
            variant: error.status,
            text: error.message
          })
        }
      } finally {
        this.loading = false
      }
    },
    getCheckInLink() {
      const path = this.organisation
      const paths = path.split('/')
      const orgName = paths[1]
      const orgPath = paths[2]
      const link = `${window.location.protocol}//${window.location.host}/qrcode.html?name=${orgName}&path=${orgPath}`
      return link
    },
    getVisitorAccessLink() {
      const path = this.organisation
      const code = this.settings.groupAccessCode
      const link = `${window.location.protocol}//${window.location.host}/api/3.0/buildings${path}/access/${code}`
      return link
    },
    getMyReservationsLink() {
      if (!this.organisation) {
        return ''
      }
      return `${window.location.protocol}//${window.location.host}/workplace/my-reservations`
    }
  }
}
</script>
<style lang="scss" scoped></style>
